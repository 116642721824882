.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-face-neuvetica {
  font-family: "Neuvetica";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header {
  position: relative;
}

.betItemChildrenAnimationGreen {
  animation: betItemChildrenAnimation-keyframes-green 10s;
}

@keyframes betItemChildrenAnimation-keyframes-green {
  0% {
    text-shadow: 1px 1px 5px #00ff0000, -1px -1px 5px #00ff0000;
  }
  2% {
    text-shadow: 1px 1px 5px #039103, -1px -1px 5px #039103;
  }
  50% {
    text-shadow: 1px 1px 5px #039103, -1px -1px 5px #039103;
  }
  100% {
    text-shadow: 1px 1px 5px #00ff0000, -1px -1px 5px #00ff0000;
  }
}

.betItemChildrenAnimationRed {
  animation: betItemChildrenAnimation-keyframes-red 10s;
}

@keyframes betItemChildrenAnimation-keyframes-red {
  0% {
    text-shadow: 1px 1px 5px #ff000000, -1px -1px 5px #ff000000;
  }
  2% {
    text-shadow: 1px 1px 5px #FF0000, -1px -1px 5px #FF0000;
  }
  50% {
    text-shadow: 1px 1px 5px #FF0000, -1px -1px 5px #FF0000;
  }
  100% {
    text-shadow: 1px 1px 5px #ff000000, -1px -1px 5px #ff000000;
  }
}


.shimmer {
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background-position: -1000px;
  -webkit-background-size: 600px 100%;
  -moz-background-size: 600px 100%;
  background-size: 600px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 100s;
  -moz-animation-duration: 100s;
  animation-duration: 100s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  /*background-position: 0 0;*/
  background-color: #222;
  animation-delay: 13s;
}

@-moz-keyframes shimmer {
  0% {
    background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background-position: 0 0;
    background-position: top right;
  }
  1% {
    background-position: top left;
  }
  2% {
    background-position: -1000px;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background-position: 0 0;
    background-position: top right;
  }
  1% {
    background-position: top left;
  }
  2% {
    background-position: -1000px;
  }
}

@-o-keyframes shimmer {
  0% {
    background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background-position: 0 0;
    background-position: top right;
  }
  1% {
    background-position: top left;
  }
  2% {
    background-position: -1000px;
  }
}

@keyframes shimmer {
  0% {
    background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
    background-position: 0 0;
    background-position: top right;
  }
  1% {
    background-position: top left;
  }
  2% {
    background-position: -1000px;
  }
}
